<template>
  <div>
    <index-navbar />
    <div class="justify-center text-center flex flex-wrap mt-24">
      <div class="w-full md:w-6/12 px-12 md:px-4">
        <h2 class="font-semibold text-4xl text-orange-500">
          Here's is some of our work
        </h2>
        <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500"></p>
      </div>
    </div>
    <section class="block relative z-1 bg-orange-500 pb-16 mt-48">
      <div class="container mx-auto">
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 -mt-24">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Online Tax Portal
                </h5>
                <a :href="OurWorks.onlineTaxPortal.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="onlineTaxPortal"
                    />
                  </div>
                </a>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Shakti Consultancy
                </h5>
                <a :href="OurWorks.shaktiConsultancy.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="shaktiConsultancy"
                    />
                  </div>
                </a>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Spark Utility
                </h5>
                <a :href="OurWorks.sparkUtility.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="sparkUtility"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  SRJBTK MANAGEMENT PORTAL
                </h5>
                <a :href="OurWorks.srjbtkManagementPortal.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="srjbtk"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  Fab Hotel
                </h5>
                <a :href="OurWorks.fabHotel.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="fabHotel"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  Mountain West App
                </h5>
                <a :href="OurWorks.mtw.link" target="_blank">
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="mtmWest"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import onlineTaxPortal from "@/assets/img/works/onlinetaxportal_home_page.jpg";
import shaktiConsultancy from "@/assets/img/works/shakti-consultancy-home.jpg";
import sparkUtility from "@/assets/img/works/sparkutility-index-html.jpg";
import srjbtk from "@/assets/img/works/stage-srjbtk-dev-biz-login.jpg";
import fabHotel from "@/assets/img/works/fabhotel-co-in-orders.jpg";
import mtmWest from "@/assets/img/works/sandbox-mtnwest-sandbox-mylisting.jpg";
import { OurWorks } from "@/Services/CommonTypes";
export default {
  data() {
    return {
      onlineTaxPortal,
      shaktiConsultancy,
      sparkUtility,
      srjbtk,
      fabHotel,
      mtmWest,
      OurWorks
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>
