<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
          <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
            we respond 1-2 business days.
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <!-- <button
              class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-twitter"></i>
            </button> -->
            <!-- <button
              class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-facebook-square"></i>
            </button> -->
            <!-- <button
              class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-dribbble"></i>
            </button> -->
            <!-- <button
              class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-github"></i>
            </button> -->
            <ul class="list-unstyled">
                <li>
                <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        <i class="fab fa-whatsapp-square mr-1"></i> 
                        <a v-bind:href="'whatsapp://'+detailInfo.whatsApp">{{detailInfo.whatsApp}}</a>
                    </p>
                </li>
                 <li>
                <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        <i class="fas fa-headphones mr-1"></i> 
                        <a v-bind:href="'tel:'+detailInfo.mobileNo">{{detailInfo.mobileNo}}</a>
                    </p>
                </li>
                <li>
                 <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        <i class="fas fa-envelope-open-text mr-1"></i> 
                        <a v-bind:href="'mailto:'+detailInfo.email">{{detailInfo.email}}</a>
                    </p>
                </li>
               
              </ul>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="block uppercase text-orange-500 text-sm font-semibold mb-2"
              >
                Our Services
              </span>
              <ul class="list-unstyled">
                <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    v-bind:href="'mailto:'+detailInfo.fbPage"                    
                  >
                   Software Application Development
                  </a> -->
                  <router-link :to="'/software-application-development'" @click.prevent="moveUp('/software-application-development')" class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Software Application Development</router-link> 
                </li>
                <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    v-bind:href="'mailto:'+detailInfo.instagram"
                  >
                    Mobile Application Development
                  </a> -->
                   <router-link to="/mobile-app-development" @click.prevent="moveUp('/mobile-app-development')"  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Mobile App Development Services</router-link> 
                </li>
                <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://www.instagram.com/invites/contact/?i=w577c7nas702&utm_content=kyip0ce"
                  >
                   Web Development Services
                  </a> -->
                  <router-link to="/web-development" @click.prevent="moveUp('/web-development')" class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Web Development Services</router-link> 
                </li>
                <!-- <li>
                  <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://www.instagram.com/invites/contact/?i=w577c7nas702&utm_content=kyip0ce"
                  >
                    E Commerce Portals
                  </a>
                </li> -->
               
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="block uppercase text-orange-500 text-sm font-semibold mb-2"
              >
               About Company
              </span>
              <ul class="list-unstyled">
                <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://www.creative-tim.com/presentation?ref=vn-footer"
                  >
                    About Us
                  </a> -->
                  <router-link to="/aboutUs" @click.prevent="moveUp('/aboutUs')"  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">About Us</router-link>
                </li>
                <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://blog.creative-tim.com?ref=vn-footer"
                  >
                    Contact Us
                  </a> -->
                  <router-link to="/contactUs" @click.prevent="moveUp('/contactUs')"  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Contact Us</router-link>
                </li>                   
                 <li>
                  <!-- <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://www.github.com/creativetimofficial?ref=vn-footer"
                  >
                    Work
                  </a> -->

                  <router-link to="/works" @click.prevent="moveUp('/works')"  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Works</router-link>
                </li>             
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="block uppercase text-orange-500 text-sm font-semibold mb-2"
              >
                Follow Us
              </span>
              <ul class="list-unstyled">
                <li>
                  <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                     v-bind:href="detailInfo.fbPage"   target="_blank"       
                  >
                   Facebook
                  </a>
                </li>
                <li>
                  <a
                    class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    v-bind:href="detailInfo.instagram"  target="_blank"      
                  >
                    Instagram
                  </a>
                </li>
               
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} by
            <a
              href="https://www.neelgagantech.com/home"
              class="text-blueGray-500 hover:text-blueGray-800"
            >
             Neelgagan Technologies.
            </a>
            All rights reserved!
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { OwnerDetail } from '@/Services/CommonTypes';
export default {
  data() {
    return {
      date: new Date().getFullYear(),
      detailInfo : OwnerDetail
    };
  },
  methods:{
    moveUp(url){
      this.$router.push(url);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
        })
        }
  }
};
</script>
