<template>
<div>
    <index-navbar />
       
     <section
      class="header"
    >
    <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="
            'background-image: url('+homeTop+');'
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="px-4">
                <h1 class="text-orange-500 font-semibold text-5xl  ">
                  WELCOME TO NEELGAGAN TECHNOLOGIES
                </h1>              
                <p class="mt-4 text-lg text-white">
              <a
                href="https://tailwindcss.com/?ref=creativetim"
                class="text-emerald-300 font-semibold"
                target="_blank"
              >
                Neelgagan Technologies
              </a>
              started Operation in 2021. we are focussed to provide the
              technology services in domain Software Application Development,
              Mobile Application Development, Web Application Development, E
              commerce, Digital Marketing etc. It features multiple HTML
              elements and it comes with dynamic components for ReactJS, Vue and
              Angular.<br />             
            </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

    
    </section>
  
   <section class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
 <p class="mt-4 text-lg text-blueGray-500">
             
              <span class="font-semibold text-blueGray-700">
                We work equally comfortable on HTML, CSS , DOT NET, DOT NET
                Core, Entity Framework, Web API, Android, PHP , PHP Framework,
                Node JS , JavaScript, React Native, Angular, VUE JS,
                SQL/Oracle/PostGreSQL etc.
              </span>
            </p>

        </div>
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold">Here are our heroes</h2>
            <!-- <p class="text-lg leading-relaxed m-4 text-blueGray-500">
              Our team equally comfortable on HTML, CSS , DOT NET, DOT NET Core,
              Entity Framework, Web API, Android, PHP , PHP Framework, Node JS ,
              JavaScript, React Native, Angular, VUE JS, SQL/Oracle/PostGreSQL
              etc.
            </p> -->
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team1 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Gagan Narang</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Software Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team2 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Vaibhav Solanki</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Senior Software Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team3 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Akshat Tulsawat</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Software Developer & UI/UX Designer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team4 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Shubham Maurya</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Java and Android Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team5 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Dhananjay Mishra</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Java and Android Developer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />
</div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import homeTop from "@/assets/img/services/aboutus.gif";
import altImg from "@/assets/img/sketch.jpg";
import team1 from "@/assets/img/teams/Gagan_Narang.jpg";
import team2 from "@/assets/img/teams/Vaibhav_Solanki.jpeg";
export default {
    data() {
    return {     
      homeTop,      
      altImg,
      team1,
      team2,
     
    };
  },
components: {
    IndexNavbar,
    FooterComponent,
  },
}
</script>
