<template>
  <div>
    <index-navbar />
    <main>
      <div
        class="
          relative
          pt-16
          pb-32
          flex
          content-center
          items-center
          justify-center
          min-h-screen-75
        "
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="'background-image: url(' + contactUs + ');'"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="px-4">
                <h1 class="text-white font-semibold text-5xl">
                  Contact With Us
                </h1>
                <!-- <p class="mt-4 text-lg text-blueGray-200">
                
                  We aim to develop technology that pleases clients and impresses users. 
                  That is why we build web apps equipped with only the most cutting-edge technology capabilities.
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            h-70-px
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <!-- <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon> -->
          </svg>
        </div>
      </div>
      <section class="pb-64 relative block bg-blueGray-800">
        <div
          class="
            bottom-auto
            top-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            -mt-20
            h-20
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <section class="block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-blueGray-200
                "
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">Write to us?</h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="full-name"
                    >
                      Full Name <span class="text-red-500"> *</span>
                    </label>
                    <input
                      type="text"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      placeholder="Full Name"
                      v-model="contactUsForm.name"
                      required
                    />
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="email"
                    >
                      Mobile <span class="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      maxlength="10"
                      minlength="10"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      v-model="contactUsForm.mobile"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="email"
                    >
                      Email <span class="text-red-500"> *</span>
                    </label>
                    <input
                      type="email"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      v-model="contactUsForm.email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="email"
                    >
                      Choose Your Interest <span class="text-red-500"> *</span>
                    </label>
                    <select
                      v-model="contactUsForm.serviceType"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      value=""
                      required
                    >
                      <option value="" disabled="">Choose your Interest</option>
                      <option value="Website Design">Website Design</option>
                      <option value="Software Development">
                        Software Development
                      </option>
                      <option value="Mobile Application Development">
                        Mobile Application Development
                      </option>
                      <option value="Website Hosting">Website Hosting</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="message"
                    >
                      Message <span class="text-red-500"> *</span>
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                      "
                      v-model="contactUsForm.message"
                      placeholder="Type a message..."
                      required
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      class="
                        bg-blueGray-800
                        text-white
                        active:bg-blueGray-600
                        text-sm
                        font-bold
                        uppercase
                        px-6
                        py-3
                        rounded
                        shadow
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        mr-1
                        mb-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      v-on:click="sendEmail()"
                    >
                      <img
                        v-if="isLoading"
                        :src="loaderImg"
                        width="40"
                        height="auto"
                      />
                      <span v-else> Send Message</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>

<script>
import { sendMail } from "../Services/emailService";
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import loaderImg from "@/assets/img/loader.gif";
import contactUs from "@/assets/img/services/aboutus3.gif";
// var dd = {
//   name: "gagan",
//   mobile: "333333",
//   email: "gggg",
//   serviceType: "afae",
//   message: "test",
// };
export default {
  data: () => {
    return {
      contactUsForm: {
        name: null,
        mobile: null,
        email: null,
        serviceType: null,
        message: null,
        date: null,
      },
      loaderImg,
      contactUs,
      isLoading: false,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
  methods: {
    sendEmail() {
      this.isLoading = true;
      this.contactUsForm.date = new Date().toDateString();
      if (this.checkValidation(this.contactUsForm)) {
        sendMail(this.contactUsForm, (data) => {
          alert("Thank you for contacting us...");
          console.log(data, "cb");
          this.isLoading = false;
          Object.assign(this.$data, this.$options.data.call(this));
        });
      } else {
        alert("Please Fill All Fields");
        this.isLoading = false;
      }
    },
    checkValidation(obj) {
      for (let key in obj) {
        if (!obj[key]) return false;
      }
      return true;
    },
  },
};
</script>
