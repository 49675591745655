export const OwnerDetail =
{
    mobileNo: '+91-9999346191',
    whatsApp: '+91-8510030888',
    address: '',
    email: 'neelgagan112@gmail.com',
    fbPage: 'https://www.facebook.com/Neelgagan-Technology-105807218155225',
    twitter: '#',
    linkIn: '#',
    pinterest: '#',
    instagram: 'https://www.instagram.com/invites/contact/?i=w577c7nas702&utm_content=kyip0ce'
};
export const OurWorks = {
    onlineTaxPortal: { link: "https://www.onlinetaxportal.com/home" },
    shaktiConsultancy: { link: "https://shakticonsultancy-751fa.web.app/" },
    sparkUtility: { link: "http://sparkutility.com/" },
    mtw: { link: "#" },
    srjbtkManagementPortal: { link: "#" },
    fabHotel: { link: "#" },
}