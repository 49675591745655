<template>
  <div class="bg-blueGray-100">
    <index-navbar />
    
    <section
      class="header bg-blueGray-100"
    >
       <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="
            'background-image: url('+homeTop+');'
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="px-4">
                <h1 class="text-orange-500 font-semibold text-5xl  ">
                  WELCOME TO NEELGAGAN TECHNOLOGIES
                </h1>              
                <p class="mt-4 text-lg text-white">
              <a
                href="https://tailwindcss.com/?ref=creativetim"
                class="text-emerald-300 font-semibold"
                target="_blank"
              >
                Neelgagan Technologies
              </a>
              started Operation in 2021. we are focussed to provide the
              technology services in domain Software Application Development,
              Mobile Application Development, Web Application Development, E
              commerce, Digital Marketing etc. It features multiple HTML
              elements and it comes with dynamic components for ReactJS, Vue and
              Angular.<br />             
            </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> -->
      </div>
    </section>

    <section class="mt-16 pb-40 relative bg-blueGray-100">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0)"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <h5 class="text-3xl font-semibold pb-4 text-center mb-8">
                  Our Services
                </h5>
        <div class="flex flex-wrap items-center">
          <div
            class="
              w-10/12
              md:w-6/12
              lg:w-4/12
              px-12
              md:px-4
              mr-auto
              ml-auto
              -mt-32
            "
          >
           
            <div
              class="
                relative
                flex flex-col
                min-w-0
                break-words
                bg-white
                w-full
                mb-6
                shadow-lg
                rounded-lg
                bg-orange-500
              "
            >
              <img
                alt="..."
                v-bind:src="ourExpertise"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-orange-500 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">Our Expertise</h4>
                <p class="text-md font-light mt-2 text-white">
                  Our team equally comfortable on HTML, CSS , DOT NET, DOT NET
                  Core, Entity Framework, Web API, Android, PHP , PHP Framework,
                  Node JS , JavaScript, React Native, Angular, VUE JS,
                  SQL/Oracle/PostGreSQL etc.
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="
                        text-orange-500
                        p-3
                        text-center
                        inline-flex
                        items-center
                        justify-center
                        w-12
                        h-12
                        mb-5
                        shadow-lg
                        rounded-full
                        bg-white
                      "
                    >
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      MOBILE APPLICATION DEVELOPMENT
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      We have expertise in multifarious apps development that
                      will be helpful in propelling the business in online
                      marketplace.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="
                        text-orange-500
                        p-3
                        text-center
                        inline-flex
                        items-center
                        justify-center
                        w-12
                        h-12
                        mb-5
                        shadow-lg
                        rounded-full
                        bg-white
                      "
                    >
                      <i class="fas fa-desktop"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      WEB DEVELOPMENT SERVICES
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Responsive web design has been trending in the IT industry
                      since 2013. This is mainly because of the growth of the
                      use of smartphones and other mobile devices.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col min-w-0 mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="
                        text-orange-500
                        p-3
                        text-center
                        inline-flex
                        items-center
                        justify-center
                        w-12
                        h-12
                        mb-5
                        shadow-lg
                        rounded-full
                        bg-white
                      "
                    >
                      <i class="fas fa-shopping-cart"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      E COMMERCE PORTALS
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Creating and building an online shopping store is a task
                      which requires experience as well as proficiency. We have
                      years of industry experience in Creating seamless and
                      secure Ecommerce store.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="
                        text-orange-500
                        p-3
                        text-center
                        inline-flex
                        items-center
                        justify-center
                        w-12
                        h-12
                        mb-5
                        shadow-lg
                        rounded-full
                        bg-white
                      "
                    >
                      <i class="fas fa-laptop-code"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      SOFTWARE APPLICATION DEVELOPMENT
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      We have expertise in multifarious apps development that
                      will be helpful in propelling the business in online
                      marketplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mx-auto overflow-hidden pb-8">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-16">
            <div
              class="
                text-orange-500
                p-3
                text-center
                inline-flex
                items-center
                justify-center
                w-16
                h-16
                mb-6
                shadow-lg
                rounded-full
                bg-white
              "
            >
              <i class="fas fa-users text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Why choose Us Upgrade your Business With Mobile & Web Development
            </h3>
            <p
              class="
                text-lg
                font-light
                leading-relaxed
                mt-4
                mb-4
                text-blueGray-600
              "
            >
              Our team of in-house developers can handle any website development
              project. web development expertise comprises responsive web
              design, e-Commerce, content management systems, social media
              integrations, etc. Our certified professionals have expertise in
              developing applications for Android platform with upgraded
              technology. We use innovative design features embedded with the
              latest technology to serve your business with excellence.
            </p>
            <!-- <div class="block pb-6">
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  uppercase
                  rounded-full
                  text-orange-500
                  bg-white
                  uppercase
                  last:mr-0
                  mr-2
                  mt-2
                "
              >
                We guarantee our customers that we provide the best possible
                solutions for your projects.
              </span>
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  uppercase
                  rounded-full
                  text-orange-500
                  bg-white
                  uppercase
                  last:mr-0
                  mr-2
                  mt-2
                "
              >
                We believe to make plans and strategies before starting our work
                to deliver your work on time.
              </span>
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  uppercase
                  rounded-full
                  text-orange-500
                  bg-white
                  uppercase
                  last:mr-0
                  mr-2
                  mt-2
                "
              >
                We offer best pricing solutions with a wide range of features
                embedded in one platform.
              </span>
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  uppercase
                  rounded-full
                  text-orange-500
                  bg-white
                  uppercase
                  last:mr-0
                  mr-2
                  mt-2
                "
              >
                We design and create web solutions for every type of business be
                it a small business, startups or large enterprise.
              </span>
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  uppercase
                  rounded-full
                  text-orange-500
                  bg-white
                  uppercase
                  last:mr-0
                  mr-2
                  mt-2
                "
              >
                We provide scalable solutions that are designed to maximize the
                client’s benefit.
              </span>
            </div> -->
            <!-- <a
              href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vn-index"
              target="_blank"
              class="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
            >
              View All
              <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </a> -->
          </div>
          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto">
            <img
              class="top-0 b-auto right-0 pt-16 -mt-48 sm:mt-0 max-h-860-px"
              :src="whyChooseUs"
              alt="..."
            />
          </div>
        </div>

        <!-- <div class="flex flex-wrap items-center pt-32">
          <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
            <div class="justify-center flex flex-wrap relative">
              <div class="my-4 w-full lg:w-6/12 px-4">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div class="bg-red-600 shadow-lg rounded-lg text-center p-8">
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/svelte.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Svelte
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/react.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      ReactJS
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/nextjs.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      NextJS
                    </p>
                  </div>
                </a>
              </div>
              <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/js/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-yellow-500 shadow-lg rounded-lg text-center p-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/js.png"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      JavaScript
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/angular/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/angular.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Angular
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/vue.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Vue.js
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-drafting-compass text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Javascript Components
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              In order to create a great User Experience some components require
              JavaScript. In this way you can manipulate the elements on the
              page and give more options to your users.
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              We created a set of Components that are dynamic and come to help
              you.
            </p>
            <div class="block pb-6">
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Alerts
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Dropdowns
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Menus
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Modals
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Navbars
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Popovers
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Tabs
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Tooltips
              </span>
            </div>
            <a
              href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vn-index"
              target="_blank"
              class="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
            >
              View all
              <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </a>
          </div>
        </div> -->
      </div>

      <!-- <div class="container mx-auto px-4 pb-32 pt-48">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-file-alt text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">
                Complex Documentation
              </h3>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                This extension comes a lot of fully coded examples that help you
                get started faster. You can adjust the colors and also the
                programming language. You can change the text and images and
                you're good to go.
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-fingerprint"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Built by Developers for Developers
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fab fa-html5"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Carefully crafted code for Components
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="far fa-paper-plane"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Dynamic Javascript Components
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-xl"
              style="
                transform: scale(1) perspective(1040px) rotateY(-11deg)
                  rotateX(2deg) rotate(2deg);
              "
              :src="documentation"
            />
          </div>
        </div>
      </div> -->

      <div class="justify-center text-center flex flex-wrap mt-8">
        <div class="w-full md:w-6/12 px-12 md:px-4">
          <h2 class="font-semibold text-4xl">Here's is some of our work</h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500"></p>
        </div>
      </div>
    </section>

    <section class="block relative z-1 bg-orange-500 pb-16">
      <div class="container mx-auto">
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 -mt-24">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Online Tax Portal
                </h5>
                <a :href="OurWorks.onlineTaxPortal.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="onlineTaxPortal"
                    />
                  </div>
                </a>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Shakti Consultancy
                </h5>
                <a :href="OurWorks.shaktiConsultancy.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="shaktiConsultancy"
                    />
                  </div>
                </a>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  Spark Utility
                </h5>
                <a :href="OurWorks.sparkUtility.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="sparkUtility"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  SRJBTK MANAGEMENT PORTAL
                </h5>
               <a :href="OurWorks.srjbtkManagementPortal.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="srjbtk"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  Fab Hotel
                </h5>
               <a :href="OurWorks.fabHotel.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="fabHotel"
                    />
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5
                  class="
                    text-xl
                    font-semibold
                    pb-4
                    text-center text-blueGray-100
                  "
                >
                  Mountain West App
                </h5>
                <a :href="OurWorks.mtw.link" target="_blank" >
                  <div
                    class="
                      hover:-mt-4
                      relative
                      flex flex-col
                      min-w-0
                      break-words
                      bg-white
                      w-full
                      mb-6
                      shadow-lg
                      rounded-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <img
                      alt="..."
                      class="
                        align-middle
                        border-none
                        max-w-full
                        h-auto
                        rounded-lg
                      "
                      :src="mtmWest"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="py-20 bg-blueGray-600 overflow-hidden">
      <div class="container mx-auto pb-64">
        <div class="flex flex-wrap justify-center">
          <div class="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-code-branch text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal text-white">
              Open Source
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400"
            >
              Since
              <a
                href="https://tailwindcss.com/?ref=creativetim"
                class="text-blueGray-300"
                target="_blank"
              >
                Tailwind CSS
              </a>
              is an open source project we wanted to continue this movement too.
              You can give this version a try to feel the design and also test
              the quality of the code!
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-400"
            >
              Get it free on Github and please help us spread the news with a
              Star!
            </p>
            <a
              href="https://github.com/creativetimofficial/vue-notus?ref=vn-index"
              target="_blank"
              class="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Github Star
            </a>
          </div>

          <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
            <i
              class="fab fa-github text-blueGray-700 text-55 absolute -top-150-px -right-100 left-auto opacity-80"
            ></i>
          </div>
        </div>
      </div>
    </section> -->

    <section class="pb-16 bg-orange-200 relative pt-48">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0)"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-orange-500 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto">
        <div
          class="
            flex flex-wrap
            justify-center
            bg-white
            shadow-xl
            rounded-lg
            -mt-64
            py-16
            px-12
            relative
            z-10
          "
        >
          <div class="w-full text-center">
             <h3 class="font-semibold text-3xl pb-16">
             Our certified professionals have expertise in
            </h3>
            <marquee behavior="scroll" scrollamount="8" direction="right">
              <img
                v-for="(img, index) of expertiseImg"
                :key="index"
                :src="img"
                height="150"
                alt=""
                width="100"
                style="
                  margin-right: 2%;
                  display: inline-block;
                  border-radius: 8px;
                "
              />
            </marquee>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold">Here are our heroes</h2>
            <!-- <p class="text-lg leading-relaxed m-4 text-blueGray-500">
              Our team equally comfortable on HTML, CSS , DOT NET, DOT NET Core,
              Entity Framework, Web API, Android, PHP , PHP Framework, Node JS ,
              JavaScript, React Native, Angular, VUE JS, SQL/Oracle/PostGreSQL
              etc.
            </p> -->
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team1 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Gagan Narang</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Software Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team2 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Vaibhav Solanki</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Senior Software Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team3 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Akshat Tulsawat</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Software Developer & UI/UX Designer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team4 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Shubham Maurya</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Java and Android Developer
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                :src="team5 ?? altImg"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Dhananjay Mishra</h5>
                <p
                  class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                >
                  Java and Android Developer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
import logo from "@/assets/img/transparent.png";
import whyChooseUs from "@/assets/img/services/aboutus2.gif";
import homeTop from "@/assets/img/services/webdevelopment.gif";
import ourExpertise from "@/assets/img/services/software3.gif";
import onlineTaxPortal from "@/assets/img/works/onlinetaxportal_home_page.jpg";
import shaktiConsultancy from "@/assets/img/works/shakti-consultancy-home.jpg";
import sparkUtility from "@/assets/img/works/sparkutility-index-html.jpg";
import srjbtk from "@/assets/img/works/stage-srjbtk-dev-biz-login.jpg";
import fabHotel from "@/assets/img/works/fabhotel-co-in-orders.jpg";
import mtmWest from "@/assets/img/works/sandbox-mtnwest-sandbox-mylisting.jpg";
import altImg from "@/assets/img/sketch.jpg";
import team1 from "@/assets/img/teams/Gagan_Narang.jpg";
import team2 from "@/assets/img/teams/Vaibhav_Solanki.jpeg";
import android from "@/assets/img/expertise/android.png";
import css from "@/assets/img/expertise/css.png";
import html from "@/assets/img/expertise/html.png";
import js from "@/assets/img/expertise/js.png";
import jquery from "@/assets/img/expertise/jquery.png";
import angular from "@/assets/img/expertise/angular.svg";
import react from "@/assets/img/expertise/react.png";
import vuejs from "@/assets/img/expertise/vuejs.png";
import nodejs from "@/assets/img/expertise/node_js.png";
import netcore from "@/assets/img/expertise/netcore.png";
import sqlserver from "@/assets/img/expertise/sqlserver.svg";
import oracle from "@/assets/img/expertise/oracle.png";
import php from "@/assets/img/expertise/php.svg";
import {OurWorks} from "@/Services/CommonTypes";
export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
      logo,
      whyChooseUs,
      homeTop,
      ourExpertise,
      onlineTaxPortal,
      shaktiConsultancy,
      sparkUtility,
      srjbtk,
      fabHotel,
      mtmWest,
      altImg,
      team1,
      team2,
      expertiseImg: [
        android,
        css,
        html,
        js,
        jquery,
        angular,
        react,
        vuejs,
        nodejs,
        netcore,
        sqlserver,
        oracle,
        php,
      ],
      OurWorks
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>
<style scoped>
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100px;
  overflow-x: hidden;
  font-size: 22pt;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 750px) {
  .marquee {
    font-size: 12px;
  }
}
</style>
