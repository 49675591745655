import { callApi, callApiPostAsJson/*  */ } from './commonMethods'

export const sendExcel = (mailOpt, cb = null) => {
    let urlLink = 'https://script.google.com/macros/s/AKfycbykHo5PPgVb3o1aED-I1bQI9QPpS2xnZTm3V415mMiLAy-CJIE/exec?';
    urlLink += new URLSearchParams(mailOpt);
    callApi(urlLink, function (data, _url = urlLink) {
        if (cb && _url) {
            cb(data);
        }
    });
}

export const sendMail = (formData, cb = null) => {
    let emailData = {
        to: 'neelgagan112@gmail.com',
        subject: `Query by user ${formData.name}`,
        message: `<table>
             <tr><td>Name</td> <td>${formData.name}</td>
            </tr>  
            <tr><td>Email</td> <td>${formData.email}</td>
            </tr>  
             <tr><td>Phone</td> <td>${formData.mobile}</td>
            </tr> 
             <tr><td>Service Type</td> <td>${formData.serviceType}</td>
            </tr> 
             <tr><td>Query</td> <td>${formData.message}</td>
            </tr>            
             </table>`,
    };
    const emailUrl = 'https://emailsend-70da4.web.app/send_email';
    callApiPostAsJson(emailUrl, emailData, null, cb);
}

