
export const callApiPostAsJson = (endpoint, body, uploads, callback) => {
    const headers = new Headers();
    headers.append("Accept", "application/json; charset=utf-8");

    let data;

    if (!uploads) {
        headers.append("Content-Type", "application/json");
        data = JSON.stringify(body);
    } else {
        data = new FormData();
        data.append("json", JSON.stringify(body));

        const files = uploads.filter(u => u.files).map(u => u.files[0]).filter(u => u);

        for (const file of files) {
            data.append("uploads", file, file.name);
        }
    }

    const options = {
        method: "POST",
        headers: headers,
        body: data
    };

    fetch(endpoint, options)
        .then(response => response.text())
        .then(response => callback(response, endpoint))
        .catch(error => errorResponse(error, callback, endpoint));
}
export function callApi(endpoint, callback) {
    const headers = new Headers();
    // headers.append("Accept", "application/json; charset=utf-8");
    headers.append("access-control-allow-origin", "*");
    // headers.append("content-type","application/json; charset=utf-8")
    // headers.append('Access-Control-Allow-Origin', 'http://localhost:9000');
    //headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    //headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    // headers.append('Access-Control-Allow-Credentials', true);

    const options = {
        method: "GET",
        headers: headers,
        mode: 'no-cors'
    };

    fetch(endpoint, options)
        .then(response => response.text())
        .then(response => callback(response, endpoint))
        .catch(error => errorResponse(error, callback, endpoint));
}
export const errorResponse = (error, callback, endpoint) => {
    // let r = confirm('Network Connection Error. Check your Internet connection. Check any cables and reboot any routers, modems, or other network devices you may be using.')
    console.log(error)
    // if (r)
    //     window.location.reload()
    alert('Something Went Wrong! Or Network Connection Error. Check your Internet connection. Check any cables and reboot any routers, modems, or other network devices you may be using.')
    return callback(JSON.stringify({ success: false, error }), endpoint)
}