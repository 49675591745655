<template>
  <div>
   <index-navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="
            'background-image: url('+desktop+');'
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="px-4">
                <h1 class="text-white font-semibold text-5xl">
                  Software Application Development
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Responsive web design has been trending in the IT industry since 2013.
                  This is mainly because of the growth of the use of smartphones and other mobile devices.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-user-tie"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Online marketplace</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                   We have expertise in multifarious apps development that will be helpful in propelling the business in online marketplace.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                    <i class="fab fa-google-play"></i>
                  </div>
                  <h6 class="text-xl font-semibold">App for your business</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                   Do you need a mobile app for your business? We are here to help you out!
                   Irrespective of the size of your project,
                   our senior project managers will work with you to select the best technology platform,
                   define the scope, and build the right app for your business.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                  >
                    <i class="fas fa-store"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Online shopping store</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                   Creating and building an online shopping store is a task which requires experience as well as proficiency.
                   <!-- We have years of industry experience in Creating seamless and secure Ecommerce store. -->
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Working with us is a pleasure
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                Don't let your uses guess by attaching tooltips and popoves to
                any element. Just make sure you enable them first via
                JavaScript.
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
              >
                The kit comes with three pre-built pages to help you get started
                faster. You can change the text and images and you're good to
                go. Just make sure you enable them first via JavaScript.
              </p>
              <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Check Vue Notus!
              </router-link>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    The Arctic Ocean freezes every winter and much of the
                    sea-ice then thaws every summer, and that process will
                    continue whatever happens.
                  </p>
                </blockquote>
              </div>
            </div>
          </div> -->
        </div>
      </section>

      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <!-- <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src="software2"
              />
            </div> -->
            <div class="w-full md:w-8/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-emerald-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-300"
                >
                  <i class="fas fa-laptop-code text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">Benefits Of Custom Software Development</h3>
                <!-- <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  The extension comes with three pre-built pages to help you get
                  started faster. You can change the text and images and you're
                  good to go.
                </p> -->
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                     
                      <div>
                        <h4 class="text-orange-500 text-lg">
                         What is customized software?
                        </h4>
                        <p class="mt-4 leading-relaxed text-blueGray-500">
                          Custom software, also known as “Bespoke” software, is a type of application developed 
                          specially to suit a particular business or an organization to fulfill its specific 
                          business-centric requirements. Customized software is generally designed for a single client, 
                          or a group of clients (customers) who decide what kind of functionality and processes the 
                          software should possess.</p>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                     
                      <div>
                        <h4 class="text-orange-500 text-lg">
                         Why is custom software development special?
                        </h4>
                        <p class="mt-4 leading-relaxed text-blueGray-500">
                          There are pros and cons of buying off-the-shelf software packages as well as developing
                          specialized software from a reliable custom software development company to suit your 
                          specific business needs. While you don’t have to spend time with the development team to 
                          design your software, in case of ready made software you can get started right away. However, 
                          such applications are generic in terms of end user requirements, and may offer certain features
                          which may not exactly fit your unique requirements. So, even after spending money, you might
                          still have to struggle with certain aspects of business automation as some features might not 
                          be available in your ready made software.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                     
                      <div>
                        <h4 class="text-orange-500 text-lg">
                         How do you know if you need custom software? 
                        </h4>
                        <p class="mt-4 leading-relaxed text-blueGray-500">
                          Determining your need for custom software can be challenging,
                          but we have a few questions we think might help. Are your current software solutions old, 
                          slow, or no longer satisfy your needs? Are you watching your competitors race ahead while 
                          it feels you’re standing in place? Do you ultimately want to save more time, make more money, 
                          and reach the next level of your professional potential? 
                          If you answered yes to even one of these questions, you are a great candidate for custom software. 
                          </p>
                      </div>
                    </div>
                  </li>
                 
                </ul>
              </div>
            </div>
             <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src="software2"
              />
            </div>
          </div>
        </div>
      </section>

    
   
    
    </main>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import software2 from "@/assets/img/services/software.gif";
import desktop from "@/assets/img/services/aboutus3.gif";
export default {
  data() {
    return {
      software2,
      desktop
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>
